import React, { useEffect, useState } from 'react';
import { ScrollView, useSx } from 'dripsy';
import Layout from '../layout';
// import Community from './community';
import Hero from './hero';
// import { Academic } from './academic';
import { Footer } from 'app/components/footer';
import { useRouter } from 'solito/router';
import { connect } from 'react-redux';
// import ExamAdda from './exam-adda';
// import Dialog from 'app/components/dialog';
// import DialogTitle from 'app/components/dialog/dialog-titel';
// import { Reader } from 'app/components/reader';
// import Banner from './banner';
// import Testimonial from './testimonial';
// import { University } from './university';

// import { Heading } from './heading';
import { useDim } from 'app/redux/hooks/useDim';
// import MarketPlace from './marketplace';
import Section from './section';
// import { makeUseAxiosHook } from 'app/redux/store';

const Home = ({ user, loaded, pageSettings, testimonial, clientConfig }) => {
  const { push } = useRouter();
  const [isSearchBox, setIsSearchBox] = useState<boolean>(true);
  const [ref, setRef] = useState<any>(null);
  useEffect(() => {
    if (!loaded) return;
    if (user) {
      push('/dashboard');
    }
  }, [user, loaded]);

  const { height } = useDim();
  const sx = useSx();

  const checkMeasure = () => {
    ref?.current?.measure(
      (_fx: any, _fy: any, _w: any, h: any, _: any, py: any) => {
        if ((py < 60 && isSearchBox) || (py >= 60 && !isSearchBox)) {
          setIsSearchBox(!(py < 60));
        }
      }
    );
  };

  return (
    <Layout
      leftMenu={false}
      setViewHeight
      showFullFooter={false}
      showSmallFooter={false}
      showNavbarBorder={false}
      showSearchBox={!isSearchBox}
      loggedInNavbar={false}
    >
      <ScrollView
        nestedScrollEnabled={true}
        onScroll={() => {
          checkMeasure();
        }}
        style={sx({
          height: height - 116,
        })}
        contentContainerStyle={{ justifyContent: 'center' }}
        scrollEventThrottle={200}
        showsVerticalScrollIndicator={false}
      >
        <Hero
          studyMaterialsCount={
            pageSettings?.homepageSettings?.studyMaterialsCount
          }
          studentsCount={pageSettings?.homepageSettings?.studentsCount}
          contributorsCount={pageSettings?.homepageSettings?.contributorsCount}
          universityCount={pageSettings?.homepageSettings?.universityCount}
          freeMaterialsCount={
            pageSettings?.homepageSettings?.freeMaterialsCount
          }
          setRef={(data: any) => setRef(data)}
        />

        {pageSettings?.newHomepageSettings?.map((section, index) => {
          return (
            <Section key={index} data={section} testimonial={testimonial} />
          );
        })}

        {/* <University
          organizations={pageSettings?.homepageSettings?.organizations || []}
          specializations={
            pageSettings?.homepageSettings?.specializations || []
          }
        />
        <ExamAdda
          openMaterial={openMaterial}
          examGenieMaterial={
            pageSettings?.homepageSettings?.examGenieMaterial || []
          }
          lnQuestMaterial={
            pageSettings?.homepageSettings?.lnQuestMaterial || []
          }
        />
        <MarketPlace />
        <Banner
          txt={
            <Text>
              <Text
                sx={{
                  display: ['block', null, null, 'inline'],
                  fontWeight: ['bold', null, null, 'normal'],
                  mb: 1,
                }}
              >
                Become a contributor.{' '}
              </Text>
              Earn a share of revenue generated by your contribution!
            </Text>
          }
          img={require('../../assets/contribution-1x.webp')}
          bgColor={clientConfig.primaryColor}
        />
        <Academic
          openMaterial={openMaterial}
          courses={pageSettings?.homepageSettings?.courses || []}
        />
        <Testimonial testimonial={testimonial} />
        <Banner
          txt={
            <Text>
              Explore All Subscription Plans Starting{' '}
              <Text
                sx={{
                  display: ['block', null, null, null, 'inline'],
                  fontWeight: ['bold', null, null, null, 'normal'],
                  marginTop: 2,
                }}
              >
                @ 199
              </Text>
            </Text>
          }
          img={require('../../assets/subscription-1x.webp')}
          bgColor={clientConfig.primaryColor}
          redirect={'/subscription'}
        /> */}
        <Footer clientConfig={clientConfig} />
        {/* 
        <Dialog
          onCloseModal={handleClose}
          modalVisible={open}
          styles={sx({
            paddingX: 1,
            maxWidth: 1483,
            width: width * 0.8,
            height: height - 122,
            marginTop: 'auto',
            mx: 'auto',
            justifyContent: 'flex-end',
            flex: 1,
          })}
          modalStyle={{
            justifyContent: 'flex-end',
            marginTop: 122,
          }}
        >
          <DialogTitle onClose={handleClose}>
            <Heading
              containerSx={{ px: 25 }}
              width={5}
              marginleft={15}
              headingName={activeMaterial?.name || ''}
              textStyle={{
                fontSize: 17,
                lineHeight: 40,
              }}
            />
          </DialogTitle>
          <View style={sx({ paddingX: 22, height: height - 122 - 91 })}>
            {activeMaterial && (
              <Reader
                materialId={activeMaterial._id || activeMaterial.id}
                showCommentBox={false}
                showReadMoreBtn={true}
                pageTimeTracking={false}
                showScrollerBox={false}
                limitPages={2}
                showsVerticalScrollIndicator={true}
              />
            )}
          </View>
        </Dialog> */}
      </ScrollView>
    </Layout>
  );
};

const mapStateToProps = ({ user, loaded, clientConfig }: any) => ({
  user,
  loaded,
  clientConfig,
});
export const HomeScreen = connect(mapStateToProps, {})(Home);
