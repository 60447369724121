/* eslint-disable react/jsx-key */

import React, { useEffect, useRef } from 'react';
import { View, Text, useSx } from 'dripsy';

import { SearchBox } from 'app/components/search';
import { ImageBackground } from 'react-native';
import { useRouter } from 'solito/router';
import { materialName } from 'app/util/helper';
// import { Carousel } from 'app/components/carousel';
import { useDim } from 'app/redux/hooks/useDim';
import { useSelector } from 'react-redux';
import { getClientConfig } from '../../redux/memoizedSelectors';

// type TextTyperDirection = 'forward' | 'back';

// interface SliderDotProps {
//   active: boolean;
// }

// const SliderDot = styled(View)((props: SliderDotProps) => ({
//   mx: 1,
//   width: 6,
//   height: 6,
//   borderRadius: 8,
//   backgroundColor: props.active ? '#6C6192' : '#D4D0E5',
// }));

interface StatsSchema {
  value: number;
  suffix: string;
}

export default function Hero({
  // studyMaterialsCount,
  // contributorsCount,
  // universityCount,
  // studentsCount,
  // freeMaterialsCount,
  setRef,
}: {
  studyMaterialsCount: StatsSchema;
  studentsCount: StatsSchema;
  universityCount: StatsSchema;
  contributorsCount: StatsSchema;
  freeMaterialsCount: StatsSchema;
  setRef: (data: any) => void;
}) {
  
  const { clientConfig } = useSelector(getClientConfig);
  const sx = useSx();
  const { push } = useRouter();
  const searchRef = useRef<any>('');
  const { width } = useDim();
  const redirectTo = (obj) => {    
    const { type,name,_source } = obj;
    if (type === 'material'|| type === 'video_material' || type === 'note' || type === 'general_material') {
      push(`/m/${_source?.id}`);
    } else {
      push(`/search/${encodeURIComponent(name)}`);
    }
  };

  useEffect(() => {
    setRef(searchRef);
  }, []);

  return (
    <View
      style={[
        sx({
          marginX: [20, null, null, null, 60],
          alignItems: 'center',
          marginBottom: [3, 3, 3, 4, 5],
          marginTop: 5,
        }),
      ]}
    >
      <View
        style={[
          sx({ borderRadius: 20, backgroundColor: '$primary', width: '100%' }),
        ]}
      >
        <ImageBackground
          style={sx({
            borderRadius: 20,
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            minHeight: [168, 168, 200, 200, 470],
          })}
          source={require('../../assets/bg-new.webp')}
          resizeMode="repeat"
        >
          <View
            style={[
              sx({
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                marginX: [3, 2],
              }),
            ]}
          >
            <Text
              style={[
                sx({
                  color: 'white',
                  fontSize: [17, 17, 17, 34],
                  fontWeight: '500',
                  textAlign: 'center',
                }),
              ]}
            >
              {'Welcome to '}
              <Text
                style={[
                  sx({
                    color: '#D8D8D8',
                    fontWeight: '700',
                  }),
                ]}
              >
               { clientConfig?.welcomeText ?? clientConfig?.name }
              </Text>
            </Text>
          </View>

          <View style={[sx({ marginTop: [20, 20, 20, 20, 60] })]}>
            <SearchBox
              searchIconSize={width > 400 ? 32 : 24}
              styles={sx({
                width: width > 400 ? width * 0.7 : width * 0.8,
                maxWidth: 1219,
                height: [36, 76],
                borderRadius: 35,
                borderBottomLeftRadius: 35,
                borderBottomRightRadius: 35,
              })}
              showRightArrowIcon={false}
              modalStyle={sx({ borderRadius: 15 })}
              placeholder={
                width > 678
                  ? `Search Topic/ Chapter/ ${clientConfig?.academicSetting?.courses}/ ${clientConfig?.academicSetting?.semester}/ ${clientConfig?.academicSetting?.university}`
                  : 'Search Topic'
              }
              placeholderTextColor="#bbbbbb"
              displayKey={(material) => materialName(material)}
              onEnterClick={(e) => e?.name?.length && redirectTo(e)}
              url="search"
              apiClient="search"
              searchKey="keywords"
              convertData={(d: any) =>
                d.data.data.map((r: any) => ({
                  ...r,
                  ...r._source,
                  note: r?.inner_hits?.syllabus?.hits?.hits[0]?._source,
                }))
              }
              onSelectData={redirectTo}
              placeholderSize={16}
              IconStyle={sx({
                fontSize: [26, 31],
                marginX: [1, 3],
                paddingLeft: [0, 2],
              })}
            />
            {/* For search bar at top navigation */}
            <View ref={searchRef}></View>
          </View>
          {/* <View style={sx({
            position: 'absolute',
            bottom: -70,
            display: ['none', 'none', 'none', 'none', 'flex']
          })}>
            <View
              style={[
                sx({
                  backgroundColor: 'white',
                  flexDirection: 'row',
                  flex: 1,
                  paddingTop: 20,
                  paddingBottom: 40,
                  paddingLeft: 40,
                  paddingRight: 40,
                  borderRadius: 20,
                  marginTop: [20, 40, 80],
                  shadowRadius: 5,
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowColor: 'rgba(0, 0, 0, 0.15)',
                }),
              ]}
            >
              {countData.map((item, index) => (
                <Item item={item} key={index} />
              ))}
            </View>
          </View> */}
        </ImageBackground>
      </View>
      {/* <View
        style={[
          sx({
            backgroundColor: 'white',
            borderRadius: 20,
            marginTop: 20,
            px: 3,
            py: 2,
            shadowRadius: 5,
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowColor: 'rgba(0, 0, 0, 0.15)',
            display: ['flex', 'flex', 'flex', 'flex', 'none'],
          }),
        ]}
      >
        <View
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 15,
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          {countData.map((e, i) => (
            <SliderDot active={i === activeIndex} />
          ))}
        </View>
        <Carousel
          {...{
            vertical: false,
            width: width >= 1120 ? (width - 300) * 0.75 : 220,
            height: width < 1120 ? 110 : 150,
          }}
          loop
          autoPlay={true}
          pagingEnabled={true}
          snapEnabled={true}
          windowSize={10}
          data={countData}
          renderItem={({ item }) => <Item item={item} />}
          onSnapToItem={(i) => setActiveIndex(i)}
        />
      </View> */}
    </View>
  );
}

// const Item = ({ item }: { item: any }) => {
//   const sx = useSx();
//   return (
//     <View
//       style={[
//         sx({
//           flexDirection: 'row',
//           marginRight: 20,
//           paddingLeft: 20,
//           paddingTop: 20,
//         }),
//       ]}
//     >
//       <View
//         style={[
//           sx({
//             backgroundColor: item.bgColor,
//             padding: [2, 2, 2, 2, 3],
//             borderRadius: 10,
//             marginRight: 20,
//           }),
//         ]}
//       >
//         <Image
//           source={item.img}
//           resizeMode="stretch"
//           style={sx({
//             width: 30,
//             height: 30,
//             resize: 'inherit',
//             margin: [1],
//           })}
//         />
//       </View>
//       <View style={[sx({ justifyContent: 'center' })]}>
//         <Text
//           style={[
//             sx({
//               fontSize: 24,
//               fontWeight: '700',
//               color: '#2F3443',
//               lineHeight: 30,
//             }),
//           ]}
//         >
//           {item.count}
//         </Text>
//         <Text
//           style={[
//             sx({
//               fontSize: [17],
//               fontWeight: '400',
//               color: '#636363',
//               lineHeight: 21,
//             }),
//           ]}
//         >
//           {item.title}
//         </Text>
//       </View>
//     </View>
//   );
// };
