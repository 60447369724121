/* eslint-disable camelcase */
import React from 'react';
import Head from 'next/head';
import { NextSeo } from 'next-seo';
import { config } from 'app/redux/config';

export const SeoHelper = (props: any) => {
  const { title, description } = props;
  let seoTitle;
  let seoDescription;
  const defaultDescription =
    ' | lecture notes, notes, PDF free download, engineering notes, university notes, best pdf notes, semester, sem, year, for all, study material';

  if (title.length > 54) {
    seoTitle = title.replace(/\s\s+| - /g, ' ').trim();
    if (seoTitle.length > 54) {
      seoTitle = seoTitle.replace(/ for| to| of/g, '');
      if (seoTitle.length > 54) {
        seoTitle = seoTitle.split('by')[0];
        // if(seoTitle.length>54){
        //     seoTitle=seoTitle.substring(0,54);
        // }
      }
    }
  } else {
    seoTitle = title;
  }
  if (description.length == 0 || !description) {
    seoDescription = title + defaultDescription;
  } else {
    seoDescription = description;
  }
  const newProps = { ...props, title: seoTitle, description: seoDescription };
  const {
    title: _title,
    description: _description,
    ogTitle = _title,
    ogDescription = _description,
    canonical,
    keywords,
    image = 'app_config.app_logo',
  } = newProps;
  return (
    <>
      <NextSeo
        // nofollow={config.baseUrl !== 'https://lecturenotes.in'}
        // noindex={config.baseUrl !== 'https://lecturenotes.in'}
        title={title}
        description={description}
        canonical={canonical}
        openGraph={{
          type: 'website',
          url: canonical,
          title: ogTitle,
          description: ogDescription,
          images: [
            {
              url: image,
              width: 800,
              height: 600,
              alt: config.app_config.app_name,
            },
            {
              url: image,
              width: 900,
              height: 800,
              alt: config.app_config.app_name,
            },
          ],
          site_name: config.app_config.app_name,
        }}
        additionalMetaTags={[
          {
            property: 'keywords',
            content: keywords,
          },
        ]}
      />
      <Head>{props.children}</Head>
    </>
  );
};
