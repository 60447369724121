import Ionicons from '@expo/vector-icons/Ionicons';
import {  TextInput, useSx, View } from 'dripsy';
import React from 'react';
import { Platform, Pressable } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { Gradient } from 'dripsy/gradient';
import { I18Text } from 'app/provider/i18-provider';


export function TextArea({
  styles = {},
  numberOfLines = 1,
  showSearchIcon = false,
  showRightArrowIcon = false,
  showSendIcon = false,
  ...props
}) {
  const sx = useSx();
  return (
    <View
      style={[
        sx({
          backgroundColor: '$white',
          borderColor: '$secondary',
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: 5,
          // padding: '5',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }),
        styles,
      ]}
    >
      {showSearchIcon ? (
        <Ionicons name="md-checkmark-circle" size={32} color="green" />
      ) : null}
      <TextInput
        multiline
        numberOfLines={numberOfLines}
        textAlignVertical="top" 
        style={[
          sx({
            padding: 2,
            flex: 1,
          }),
          sx({
            ...Platform.select({
              web: {
                outline: 'none',
              },
              native: {},
            }),
          }),
        ]}
        {...props}
      />
      {showRightArrowIcon ? (
        <Ionicons name="md-checkmark-circle" size={32} color="green" />
      ) : <></>}
      {showSendIcon ? <Pressable {...props} style={{ position: 'absolute', bottom: 5, right: 5 }} >
        <Gradient
          colors={['$secondary', '$primary']}
          style={sx({
            flex: 1,
            flexDirection: 'row',
            borderRadius: 5,
            padding: 1,
            justifyContent: 'center',
            alignItems: 'center'
          })}
        >
          <Feather name="send" size={12} color="white" />
          <I18Text
            style={[
              sx({
                fontSize: 12,
                color: 'white',
                paddingLeft: 1
              }),
            ]}
          >
            Send
          </I18Text>
        </Gradient>
      </Pressable> : <></>}
    </View>
  );
}
