import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export const VideoJS = (props: any) => {
  const videoRef = React.useRef<any>(null);
  const playerRef = React.useRef<any>(null);
  const { options, onProgress, onError } = props;

  React.useEffect(() => {

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
      const videoElement = document.createElement('video-js');
      console.log(props.width, props.height);
      videoElement.style.width = 'inherit';
      videoElement.style.height = 'inherit'; //`${props.height}px`;
      videoElement.style.backgroundColor = 'transparent';

      videoElement.classList.add('vjs-big-play-centered');
      if (videoRef.current) videoRef.current.appendChild(videoElement);

      const player = playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        onProgress && player.on('timeupdate', () => {
          onProgress({ playedSeconds: player.currentTime() });
        });
        player.on('error', () => {
          videojs.log('Error:', player.error());
          onError && onError(player.error());
        });
      });
      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);

    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player style={{
      width: props.width || '100%',
      height: props.height || '100%',
      // marginBottom: '120px',
    }}>
      <div ref={videoRef} style={{
        width: props.width || '100%',
        height: props.height || '100%',
        // marginBottom: '120px',
      }} />
    </div>
  );
};

export default VideoJS;