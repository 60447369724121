import React from 'react';
import { Skeleton as SkeletonMoti } from 'moti/skeleton';
import { styled } from 'dripsy';

export const Skeleton = styled((props: any) => {
  return (
    <SkeletonMoti
      width="100%"
      colorMode="light"
      radius={5}
      {...props}
    />
  );
})({});
