import { View, useSx } from 'dripsy';
import React from 'react';
import { QuillViewer } from 'app/components/quill-viewer';

export default function TextType({ txt }) {
  // const { width } = useDim();

  const sx = useSx();

  return (
    <View style={[sx({ marginBottom: [30, 50] })]}>
      <View
        style={[
          sx({
            backgroundColor: '#C4E7DB',
            borderRadius: [10, 16],
            px: [20, 20, 50],
            py: [20, 20, 34],
            flexDirection: 'row',

            marginBottom: [30, 50],
            mt: [50],
          }),
        ]}
      >
        <View
          style={[
            sx({
              alignItems: ['start', null, null, 'center'],
              flexDirection: ['column', null, null, 'row'],
              flex: 1,
              //   paddingLeft: [15, 20, 60],
            }),
          ]}
        >
          {/* <Text
            style={[
              sx({
                color: '#000',
                justifyContent: 'center',
                flex: 1,
                fontSize: [15, 15, 24],
                mb: [3, null, null, 0],
                fontWeight: '500',
              }),
            ]}
          >
            {txt}
          </Text> */}
          <QuillViewer
            containerStyles={sx({
              color: '#000',
              justifyContent: 'center',
              flex: 1,
              fontSize: [15, 15, 24],
              mb: [3, null, null, 0],
              fontWeight: '500',
            })}
            html={txt}
          />
        </View>
      </View>
    </View>
  );
}
