import { View, useSx } from 'dripsy';
import React, { useMemo } from 'react';
import { useDim } from 'app/redux/hooks/useDim';
import { Carousel } from 'app/components/carousel';
export default function CarouselType({ data, renderItem }) {
  const { width } = useDim();
  const carouselSizeWidth = useMemo(() => {
    if (width >= 1400) {
      return 1480;
    } else if (width >= 1024) {
      return 1024;
    } else if (width >= 768) {
      return 1024;
    } else if (width >= 640) {
      return 1024;
    } else {
      return 310;
    }
  }, [width]);

  const sx = useSx();

  return (
    <View style={[sx({ marginBottom: [3, 4] })]}>
      <Carousel
        {...{
          vertical: false,
          height: 380,
        }}
        style={sx({
          width: ['100%', carouselSizeWidth],
        })}
        loop
        width={carouselSizeWidth}
        autoPlay={true}
        pagingEnabled={true}
        snapEnabled={true}
        windowSize={10}
        data={data}
        renderItem={renderItem}
        sliderDot
      />
    </View>
  );
}
