/* eslint-disable react/jsx-key */
import React from 'react';
import { View, Image, useSx } from 'dripsy';
import { Heading } from './heading';
import { Container } from 'app/components/container2';
import TextType from './text';
import VideoType from './video';
import Testimonial from './testimonial';
import CarouselType from './carouselType';
import { ResizeMode } from 'expo-av';
import { Platform } from 'react-native';
import { useDim } from 'app/redux/hooks/useDim.native';
import VideoPlayer from 'app/components/video-player';
import ContentList from './content-list';

export default function Section({ testimonial, data = {} }: any) {
  const sx = useSx();
  const { heading, subHeading, details, type } = data;
  const { width } = useDim();
  const RenderVideo = (playback, thumbnail) => {
    return (
      <VideoPlayer
        reactPlayerProps={{
          height: Platform.OS !== 'web' || width <= 600 ? 210 : 400,
          playing: true,
          autoplay: true,
        }}
        containerStyle={{
          width: '100%',
          minHeight: Platform.OS !== 'web' || width <= 600 ? 210 : 300,
          overflow: 'hidden',
          borderRadius: 20,
          position: 'relative',
          minWidth: 440,
          maxHeight: 360,
          height: '100%',
          // borderRadius: 27,
        }}
        height={310}
        width={width}
        sources={{
          uri: Platform.OS !== 'web' ? playback?.dash : playback?.hls,
          thumbnail: thumbnail,
        }}
      />
    );
  };

  const renderSection = (sectionType) => {
    const mediaData: any = details?.imageUrl.length
      ? details?.imageUrl
      : details?.selectedVideos || [];
    switch (sectionType) {
      case 'text':
        return <TextType txt={details?.text} />;
      case 'text&media':
        return <VideoType details={details} />;
      case 'carousel':
        return (
          <CarouselType
            key={type}
            data={mediaData}
            renderItem={({ index, item }) =>
              details?.imageUrl.length ? (
                <Image
                  key={index}
                  source={item}
                  style={{ width: '100%', height: '100%', borderRadius: 20 }}
                  resizeMode={ResizeMode.STRETCH}
                />
              ) : (
                RenderVideo(item?.playback, item?.thumbnail)
              )
            }
          />
        );
      case 'testimonial':
        return <Testimonial testimonial={testimonial} />;
      case 'lists':
        return (
          <ContentList
            details={{ ...details, contentList: details?.contentList || [] }}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Container>
      {!!Object.keys(data).length && (
        <View style={[sx({ marginBottom: [2, 4] })]}>
          <Heading
            headingName={heading}
            subHeading={subHeading}
            width={5}
            marginleft={15}
          />
          {renderSection(type)}
        </View>
      )}
    </Container>
  );
}
