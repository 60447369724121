import { View } from 'dripsy';
import React from 'react';
import { TextStyle } from 'react-native';

interface QuillViewerProps {
  html: any;
}

export const QuillViewer = ({ html, containerStyles }: QuillViewerProps & {containerStyles: TextStyle}) => {

  return (
    <View
      style={[{
        borderWidth: 0,
        padding: '2 !important',
      }, containerStyles]}
    >
      <div
        style={{
          padding: '0 !important',
          overflow: 'auto',
        }}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </View>
  );
};
