import { useWindowDimensions } from 'react-native';


export const useDim = () => {
  const { width, height, fontScale } = useWindowDimensions();
  const scale = 1;
  return {
    width: width * Math.max(scale || 1, 1),
    height: height * Math.max(scale || 1, 1),
    scale: Math.max(scale || 1, 1),
    fontScale,
  };
};
