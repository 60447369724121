import React, { useEffect, useState } from 'react';
import { HomeScreen } from 'app/features/home';
import { SeoHelper } from 'app/components/seo-helper';
import { client, makeUseAxiosHook } from 'app/redux/store';
import { redirect } from 'app/util/helper';
import { store } from 'app/redux/store';

const Home = ({ testimonial,pageSettings }) => {
  const globalStoreData = store.getState();
  const { clientConfig } = globalStoreData || {};
  const academic =makeUseAxiosHook(
    'academic'
  );
  const [, getPageSettings] = academic({
    url: '/page-settings?$populate=newHomepageSettings',
    method: 'GET',
  });
  const [, getTestimonial] = academic({
    url: '/api/v1/testimonial?active=true&url=/',
    method: 'GET',
  });

  const [initialProps,setInitialProps]=useState({
    testimonial:[],
    pageSettings:{}
  });

  useEffect(()=>{
    if(pageSettings?._id){
      setInitialProps({ testimonial,pageSettings });
    }else{
      const resData:any={};
      getPageSettings().then((res)=>{
        resData.pageSettings=res.data;
      }).catch((e) => {
        console.error(e);
        resData.pageSettings={};
      });
      getTestimonial().then((res)=>{
        resData.testimonial=res?.data?.data;
      }).catch(e => {
        console.error(e);
        resData.testimonial=[];
      });
      setInitialProps(resData);
    }
  },[testimonial,pageSettings]);

  return (
    <>
      <SeoHelper
        // title={`Handwritten Notes & Study Materials Online | ${clientConfig?.name}`}
        title={`${clientConfig.name}`}
        description={
          `${clientConfig?.name} brings free study materials online like toppers handwritten notes & study notes for exam preparation. Download online free notes in just a click.`
        }
        image={
          'https://upload-production-s3.s3.ap-south-1.amazonaws.com/lecturenotes-assets/ln-og-image.png'
        }
        keywords={
          'Lecture notes, toppers notes, Handwritten Notes, study notes, online free notes, student notes, free study material online, learning notes, online notes'
        }
        ogTitle={`${clientConfig?.name} | Handwritten Notes & Study Materials Online`}
        canonical="https://lecturenotes.in/"
        ogDescription={
          `${clientConfig?.name} brings all your study material online and enhances your learning journey. Our team will help you for exam preparations with study notes and previous year papers.`
        }
      ></SeoHelper>
      <HomeScreen {...initialProps} />
    </>
  );
};

Home.getInitialProps = async (ctx: any) => {
  const {
    query: { token },
  } = ctx;
  if (token) {
    redirect(ctx, '/dashboard');
  }
  const pageSettings = await client['academic'].client
    .get('/page-settings?$populate=newHomepageSettings')
    .catch(() => {
      return {};
    });

  const testimonial = await client['academic'].client
    .get('/api/v1/testimonial?active=true&url=/')
    .catch(() => {
      return {};
    });

  return {
    pageSettings: pageSettings?.data || {},
    testimonial: testimonial?.data?.data || {},
  };
};

export default Home;
