import ReanimatedCarousel from 'react-native-reanimated-carousel';
import React, { useMemo, useRef, useState } from 'react';
import { Pressable, View, useSx } from 'dripsy';
import { ViewStyle } from 'react-native';
import { MotiPressable } from 'moti/interactions';
import { Icon } from '../icon/icon';

type dotPositionType = 'top' | 'bottom' | 'right' | 'left' | 'center';
type sliderArrowProperties = {
  color?: string;
  size?: number;
  disableColor?: string;
}
type sliderDotProperties = {
  color?: string;
  size?: number;
  activeColor?: string;
}
interface CarouselProps {
  data: any[];
  loop?: boolean;
  sliderDotStyle?: ViewStyle;
  activeSliderDotStyle?: ViewStyle;
  sliderDot?: boolean;
  sliderDotPosition?: dotPositionType;
  sliderDotContainerStyle?: ViewStyle;
  sliderArrow?: boolean;
  sliderArrowProps?: sliderArrowProperties;
  sliderDotProps?: sliderDotProperties;
}

export const Carousel = (props: any) => {
  const { data, sliderDotStyle, sliderDot = false, activeSliderDotStyle, sliderDotPosition, sliderDotContainerStyle, sliderArrow = false, loop, sliderArrowProps, sliderDotProps }: CarouselProps = props;
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const sliderRef = useRef<any>(null);

  return (
    <View>
      <ReanimatedCarousel
        ref={sliderRef}
        panGestureHandlerProps={{
          activeOffsetX: [-10, 10],
        }}
        onSnapToItem={(i) => setActiveIndex(i)}
        {...props}
      />

      {!!data?.length && sliderArrow && (
        <>
          <SliderArrow
            onPress={() => {
              const currentIndex = activeIndex - 1;
              setActiveIndex(currentIndex);
              sliderRef.current?.scrollTo({ index: currentIndex });
            }}
            disable={loop ? false : activeIndex <= 0}
            sliderArrowProps={sliderArrowProps}
            reverse
          />
          <SliderArrow
            onPress={() => {
              const currentIndex = activeIndex + 1;
              setActiveIndex(currentIndex);
              sliderRef.current?.scrollTo({ index: currentIndex });
            }}
            disable={loop ? false : activeIndex + 1 >= data.length}
            sliderArrowProps={sliderArrowProps}
          />
        </>
      )}

      {sliderDot && <SliderDot
        activeIndex={activeIndex}
        data={data}
        sliderRef={sliderRef}
        setActiveIndex={(index) => setActiveIndex(index)}
        sliderDotStyle={sliderDotStyle}
        activeSliderDotStyle={activeSliderDotStyle}
        sliderDotPosition={sliderDotPosition}
        sliderDotContainerStyle={sliderDotContainerStyle}
        sliderDotProps={sliderDotProps}
      />
      }
    </View>
  );
};

const SliderArrow = ({
  onPress,
  reverse,
  disable,
  sliderArrowProps
}: {
  onPress?: () => void;
  reverse?: boolean;
  disable?: boolean;
  sliderArrowProps?: any;
}) => {
  const sx = useSx();
  const { color = '#6C6192', disableColor = '#D4D0E5', size = 20 } = sliderArrowProps || {};

  return (
    <MotiPressable
      containerStyle={sx({
        position: 'absolute',
        top: 0,
        left: reverse ? 0 : 'auto',
        right: reverse ? 'auto' : 0,
        bottom: 0,
      })}
      style={sx({ flex: 1, justifyContent: 'center' })}
      onPress={() => {
        if (disable) return;
        onPress && onPress();
      }}
      animate={useMemo(
        () =>
          ({ hovered, pressed }) => {
            'worklet';
            return {
              opacity: hovered || pressed ? 0.5 : 1,
            };
          },
        []
      )}
    >
      <Icon name='downArrow' width={size} height={size} fill={disable ? [disableColor] : [color]} style={[
        ({
          transform: [{ rotate: reverse ? '90deg' : '-90deg' }]
        }),
      ]} />
    </MotiPressable>
  );
};
interface SliderDotProp {
  activeIndex: number;
  sliderRef: any;
  data: any;
  setActiveIndex: (data: any) => void;
  sliderDotStyle?: ViewStyle;
  activeSliderDotStyle?: ViewStyle;
  sliderDotPosition?: dotPositionType;
  sliderDotContainerStyle?: ViewStyle;
  sliderDotProps?: any;
}

const SliderDot = ({ activeIndex, data, sliderRef, setActiveIndex, sliderDotStyle, sliderDotPosition = 'bottom', activeSliderDotStyle, sliderDotContainerStyle, sliderDotProps }: SliderDotProp) => {

  const sx = useSx();
  const verticalDot = ['right', 'left']?.includes(sliderDotPosition);
  const { color = '#D4D0E5', activeColor = '#6C6192', size = 6, radius = 8 } = sliderDotProps || {};

  return (
    <View style={[sx({
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
    }),
    verticalDot ? [sx({
      flexDirection: 'column',
      width: 'auto',
      height: '100%'
    }),
    sliderDotPosition == 'left' ? sx({
      left: 1
    }) : sx({
      right: 1
    })
    ] : [sx({
      flexDirection: 'row',
      width: '100%',
      height: 'auto',
    }),
    sliderDotPosition == 'bottom' ? sx({
      bottom: 1,
    }) : sx({
      top: 1,
    })
    ],
      sliderDotContainerStyle]}>
      {(data ? data : [])?.map((e: any, i: number) => (
        <Pressable
          onPress={() => {
            setActiveIndex(i);
            sliderRef?.current?.scrollTo({ index: i });
          }}
          key={i}
        >
          <View style={[sx({
            m: 1,
            width: size,
            height: size,
            borderRadius: radius,
            backgroundColor: color,
          }), sliderDotStyle, i == activeIndex && [sx({
            backgroundColor: activeColor
          }), activeSliderDotStyle]]} />
        </Pressable>
      ))}
    </View>
  );

};