/* eslint-disable react/jsx-key */
import React, { useMemo, useRef, useState } from 'react';
import { View, Image, Text, useSx, styled } from 'dripsy';
import { FlatList } from 'react-native';
// import { Heading } from './heading';
import { Container } from 'app/components/container2';
import { Carousel } from 'app/components/carousel';
import { AntDesign } from '@expo/vector-icons';
import { MotiPressable } from 'moti/interactions';
import { useDim } from 'app/redux/hooks/useDim';

export default function Testimonial({ testimonial }) {
  const sx = useSx();
  const { width } = useDim();
  const testimonialWidth = useMemo(() => {
    return width - 50;
  }, [width]);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [contentOffset, setContentOffset] = useState<number>(0);

  const sliderRef = useRef<any>();
  return (
    <Container>
      <View style={[sx({ marginBottom: [30, 50] })]}>
        {/* <Heading
          headingName={'Testimonials'}
          subHeading={'Listen from our users what they say?'}
          width={5}
          marginleft={15}
        /> */}
        <View style={[sx({ padding: 0, marginLeft: 0 })]}>
          {width > 1120 ? (
            <View sx={{ padding: [1, 0], position: 'relative', marginLeft: 0, marginTop: 3 }}>
              <FlatList
                ref={sliderRef}
                style={sx({ margin: [1, 0], marginTop: 1 })}
                data={testimonial ? testimonial : []}
                showsHorizontalScrollIndicator={false}
                horizontal={true}
                renderItem={({ item }) => <Item item={item} />}
                onScroll={(e) =>
                  setContentOffset(e.nativeEvent.contentOffset.x)
                }
              />
              {testimonial?.testimonial?.length > 3 && (
                <>
                  <SliderArrow
                    onPress={() => {
                      sliderRef?.current?.scrollToOffset({
                        offset: contentOffset + 300,
                      });
                    }}
                  />
                  <SliderArrow
                    onPress={() => {
                      sliderRef?.current?.scrollToOffset({
                        offset: contentOffset - 300,
                      });
                    }}
                    reverse
                  />
                </>
              )}
            </View>
          ) : (
            testimonial && testimonial?.length ? <>
              <View
                sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  bottom: 1,
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                {(testimonial)?.map((e, i) => (
                  <SliderDot active={i === activeIndex} />
                ))}
              </View>
              <Carousel
                {...{
                  vertical: false,
                  width: testimonialWidth,
                  height: 380,
                }}
                loop
                autoPlay={true}
                pagingEnabled={true}
                snapEnabled={true}
                windowSize={10}
                data={testimonial}
                renderItem={({ item }) => <Item item={item} />}
                onSnapToItem={(i) => setActiveIndex(i)}
              />
            </>:<></>
          )}
        </View>
      </View>
    </Container>
  );
}

function Item({ item }: { item: any }) {
  const sx = useSx();
  return (
    <View
      style={[
        sx({
          backgroundColor: 'white',
          borderRadius: 20,
          shadowColor: 'rgba(0, 0, 0, 0.15)',
          shadowRadius: 4,
          padding: [17, 17, 30],
          justifyContent: 'space-between',
          marginRight: [1, null, null, 4],
          marginLeft: 1,
          marginY: 1,
          height: '90%',
          width: [null, null, null, 550],
        }),
      ]}
    >
      <View>
        <Text
          style={[
            sx({
              fontWeight: '600',
              fontSize: [18, null, null, 22],
              lineHeight: [22, 28],
              color: '#2F3443',
            }),
          ]}
        >
          &quot;{item.jobtitle}&quot;
        </Text>
        <Text
          style={[
            sx({
              fontWeight: '400',
              fontSize: [15, null, 17],
              color: '#636363',
              marginTop: 10,
              marginBottom: 10,
              maxHeight: 180,
              overflow: 'hidden',
            }),
          ]}
        >
          {item.comment}
        </Text>
      </View>
      <View style={[sx({ flexDirection: 'row', alignItems: 'center' })]}>
        <Image
          resizeMode="stretch"
          style={sx({
            width: [45, null, 60],
            height: [45, null, 60],
            borderRadius: 35,
          })}
          source={{
            uri: item.avatar ? item.avatar : require('../../assets/user.svg'),
          }}
        />
        <View style={[sx({ marginLeft: [15, 15, 40] })]}>
          <Text
            style={[
              sx({
                fontWeight: '600',
                fontSize: [15, null, 18],
                color: '#2F3443',
                width: [200, 300, 300],
              }),
            ]}
          >
            {item.username}
          </Text>
          <Text
            style={[
              sx({
                fontWeight: '400',
                fontSize: [14, null, 18],
                color: '#636363',
                width: [200, 300, 300],
              }),
            ]}
          >
            {item.jobtitle}
          </Text>
        </View>
      </View>
    </View>
  );
}

const SliderArrow = ({
  onPress,
  reverse,
}: {
  onPress?: () => void;
  reverse?: boolean;
}) => {
  const sx = useSx();
  return (
    <MotiPressable
      containerStyle={sx({
        position: 'absolute',
        top: 0,
        left: reverse ? 0 : 'auto',
        right: reverse ? 'auto' : 0,
        bottom: 0,
      })}
      style={sx({ flex: 1, justifyContent: 'center' })}
      onPress={onPress}
      animate={useMemo(
        () =>
          ({ hovered, pressed }) => {
            'worklet';
            return {
              opacity: hovered || pressed ? 0.5 : 1,
            };
          },
        []
      )}
    >
      <View sx={{ backgroundColor: '#949494', borderRadius: 4, padding: 2 }}>
        <AntDesign
          name={reverse ? 'left' : 'right'}
          style={sx({ color: '$white', fontSize: 24 })}
        />
      </View>
    </MotiPressable>
  );
};

interface SliderDotProps {
  active: boolean;
}
const SliderDot = styled(View)((props: SliderDotProps) => ({
  mx: 1,
  width: 6,
  height: 6,
  borderRadius: 8,
  backgroundColor: props.active ? '#6C6192' : '#D4D0E5',
}));
