/* eslint-disable react/jsx-key */
import React from 'react';
import { View, Image, useSx } from 'dripsy';
import { useDim } from 'app/redux/hooks/useDim';
import { ResizeMode } from 'expo-av';
import { QuillViewer } from 'app/components/quill-viewer';
import VideoPlayer from 'app/components/video-player';
import { Platform } from 'react-native';

export default function VideoType({ details }) {
  const { width } = useDim();

  const sx = useSx();
  return (
    <View style={[sx({ marginBottom: [30, 50] })]}>
      <View
        style={[
          sx({
            alignItems: ['stretch', null, null, null, 'center'],
            justifyContent: 'space-between',
            flexDirection: [
              'column-reverse',
              'column-reverse',
              'column-reverse',
              'column-reverse',
              details?.mediaOrientation === 'left' ? 'row-reverse' : 'row',
            ],
            backgroundColor: '#F3F1FA',
            paddingY: [30, 20],
            borderRadius: 27,
            marginTop: 4,
          }),
        ]}
      >
        <View
          style={[
            sx({
              paddingRight: 3,
              paddingLeft: [3, 3, 3, 3, 50],
              paddingTop: [4, 4, 4, 4, 0],
              flex: [null, null, null, null, 1],
              alignItems: ['center', null, null, null, 'flex-start'],
              justifyContent: [
                'flex-start',
                'flex-start',
                'flex-start',
                'flex-start',
                details?.mediaOrientation === 'left' ? 'flex-end' : 'center',
              ],
              // height: '100%',
              // display: 'flex',
              // backgroundColor: 'red',
            }),
          ]}
        >
          <QuillViewer
            containerStyles={sx({
              fontSize: [18, 24],
              lineHeight: [27, 27, 27, 40],
            })}
            html={details?.text || ''}
          />
        </View>
        <View
          style={sx({
            alignItems: 'center',
            paddingLeft: [20, 20, 20, 20, 55],
            paddingRight: [20, 20, 20, 20, 40],
          })}
        >
          <View sx={{ flexDirection: 'column' }}>
            <View
              style={[
                sx({
                  borderRadius: 25,
                  // marginTop: -70,
                  backgroundColor: 'white',
                  shadowRadius: 3,
                  shadowOffset: {
                    width: 0,
                    height: 0,
                  },
                  shadowColor: 'rgba(0, 0, 0, 0.15)',
                  maxWidth: [300, 540],
                  maxHeight: [300, 360],
                  width: width,
                }),
              ]}
            >
              {details?.imageUrl.length ? (
                <Image
                  source={{ uri: details?.imageUrl[0] || '' }}
                  style={[
                    sx({
                      width: [300, 540],
                      height: [300, 330],
                      borderRadius: 25,
                    }),
                  ]}
                  resizeMode={ResizeMode.STRETCH}
                />
              ) : (
                <></>
              )}
              {details?.selectedVideos.length ? (
                <VideoPlayer
                  reactPlayerProps={{
                    height: Platform.OS !== 'web' || width <= 600 ? 210 : 320,
                    playing: true,
                    autoplay: true,
                  }}
                  containerStyle={{
                    width: '100%',
                    minHeight:
                      Platform.OS !== 'web' || width <= 600 ? 210 : 320,
                    overflow: 'hidden',
                    borderRadius: 20,
                    position: 'relative',
                  }}
                  height={310}
                  width={width}
                  sources={{
                    uri:
                      Platform.OS !== 'web'
                        ? details?.selectedVideos[0]?.playback?.hls?.dash
                        : details?.selectedVideos[0]?.playback?.hls,
                    thumbnail: details?.selectedVideos[0]?.thumbnail,
                  }}
                />
              ) : (
                <></>
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
