import React, { useMemo } from 'react';
import { View, useSx, useDripsyTheme, Image, Text } from 'dripsy';
import { MotiPressable } from 'moti/interactions';
import { useRouter } from 'solito/router';
import { getContrastYIQ, stringToColour } from 'app/util/helper';
// import { Button } from 'app/components/button/common-button';
// import { Icon } from 'app/components/icon/icon';
// import { useCurrentRoute } from 'app/redux/hooks/use-current-route';
// import { checkUserAccess } from 'app/redux/hooks/check-user-access';
// import { useSelector } from 'react-redux';

interface ExamCategoriesCardProps {
  _id: string;
  name: string;
  imgUrl: string;
  href: string;
  nextExamDate: string;
  totalTestCount?: number;
  isAvailableForPurchase?: boolean;
  productTypeId?: string,
  purchaseType?: string
}


function ExamCategoriesCard({
  _id,
  name = 'Name',
  imgUrl = '',
  href,
  nextExamDate = '',
  totalTestCount=0,

}: ExamCategoriesCardProps) {
  const sx = useSx();
  const { theme }: any = useDripsyTheme();
  const { push } = useRouter();
  
  return (
    <MotiPressable
      onPress={() => {
        push('/[slug]', `${href}`);
      }}
      animate={useMemo(
        () =>
          ({ hovered, pressed }) => {
            'worklet';

            return {
              borderColor:
                hovered || pressed
                  ? theme?.colors['$primary']
                  : theme?.colors['$white'],
              borderWidth: 1,
            };
          },
        []
      )}
      style={[
        sx({
          margin: [1, 2],
          minHeight: [170],
          paddingX: [2, 3],
          paddingY: 2,
          borderRadius: 6,
          backgroundColor: '$white',
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 1,
          },
          shadowOpacity: 0.22,
          shadowRadius: 2.22,
          elevation: 3,
          justifyContent: 'space-between',
          minWidth: [320, 360],
        }),
        {
          borderWidth: 0.1,
        },
      ]}
    >
      <View
        style={[
          sx({
            flexDirection: 'row',
            flex: 1,
            paddingY: 2,
            minHeight: 90,
          }),
        ]}
      >
        <View
          style={sx({
            borderColor: '#C9C9C9',
            borderWidth: 1,
            borderRadius: 7,
            marginRight: [3],
            backgroundColor: '#F2F2F2',
            height: 100,
            padding: 1,
          })}
        >
          {!!imgUrl?.length ? (
            <Image
              source={{
                uri: imgUrl,
              }}
              resizeMode={'cover'}
              resizeMethod={'scale'}
              style={sx({
                width: 70,
                height: 80,
                borderRadius: 5,
                margin: 1,
              })}
            />
          ) : (
            <View
              style={{
                backgroundColor: stringToColour(`${_id}`),
                padding: 20,
                margin: 2,
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                width: 70,
                borderRadius: 5,
              }}
            >
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: 16,
                  color: getContrastYIQ(stringToColour(`${name}`).substring(1)),
                }}
              >
                {
                  (name
                    ? name
                      .match(/\b(\w)/g)
                      ?.join('')
                      ?.substring(0, 3)
                      ?.toUpperCase()
                    : '') as string
                }
              </Text>
            </View>
          )}
        </View>
        <View>
          <Text
            numberOfLines={2}
            ellipsizeMode="tail"
            lineBreakMode="clip"
            style={sx({
              fontWeight: '600',
              fontSize: [13, 17],
              lineHeight: [17, 21],
              flexShrink: 1,
              marginBottom: 2,
            })}
          >
            {name || 'Exam Name'}
          </Text>
          <Text
            numberOfLines={2}
            ellipsizeMode="tail"
            lineBreakMode="clip"
            style={sx({
              fontSize: [13, 17],
              lineHeight: [17, 21],
              flexShrink: 1,
            })}
          >
            {`Total tests : ${totalTestCount}`}
          </Text>
        </View>
      </View>
      <View
        style={[
          sx({
            borderTopWidth: 1,
            borderTopColor: '#F4F4F4',
            marginTop: 2,
            flexDirection: ['row'],
            justifyContent: 'space-between',
            alignItems: ['center'],
            flexWrap: 'wrap',
            paddingTop: 0,
            minHeight: [30, 30, 40],
          }),
        ]}
      >
        {nextExamDate && (
          <Text>Tentative exam date: {nextExamDate}</Text>
        )}

        {/* {!userPermission?.isAdmin && clientConfig?.billing && isAvailableForPurchase ?
          <Button
            onPress={() => handleClassroomToBuy()}
            variantType='outlined'
            startIcon={
              <Icon name='cart'
                width={18}
                height={18}
              />
            }
            startIconStyle={{
              marginRight: 0
            }}
            style={{ height: 30 }}
          >Buy now</Button> : <></>
        } */}

      </View>
    </MotiPressable>
  );
}

export default ExamCategoriesCard;
