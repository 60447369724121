import React, { memo, useMemo, useRef } from 'react';
// import { youTubeGetID } from 'app/util/helper';
import {
  Video,
  VideoFullscreenUpdateEvent,
  VideoFullscreenUpdate,
  ResizeMode,
} from 'expo-av';
import ReactPlayer from 'react-player';
import { Platform } from 'react-native';
import { View } from 'dripsy';
// import { ReactPlayerProps } from 'react-player';
import * as ScreenOrientation from 'expo-screen-orientation';
import WebView from 'react-native-webview';
import { cleanObject } from 'app/util/helper';
import WebVideo from './video';
interface ReactVideoPlayerProps {
  sources: {
    uri: string;
    thumbnail: string | undefined;
  };
  type?: string;
  height?: any;
  width?: any;
  reactPlayerProps?: any;
  getWatchDuration?: (data: any) => any | undefined;
  containerStyle?: any;
  onError?: (data: any) => any | undefined;
}

function VideoPlayer({
  type = 'youtube',
  sources: _sources,
  height = '100%',
  width = '100%',
  // reactPlayerProps = {},
  getWatchDuration = () => '',
  containerStyle = {},
  onError
}: ReactVideoPlayerProps) {
  const onFullscreenUpdate = async ({
    fullscreenUpdate,
  }: VideoFullscreenUpdateEvent) => {
    switch (fullscreenUpdate) {
      case VideoFullscreenUpdate.PLAYER_DID_PRESENT:
        await ScreenOrientation.lockAsync(
          ScreenOrientation.OrientationLock.LANDSCAPE
        ) // only on Android required
          .catch((e) => console.error(e)); // only on Android required
        break;
      case VideoFullscreenUpdate.PLAYER_WILL_DISMISS:
        await ScreenOrientation.unlockAsync();
        break;
    }
  };
  const videoRef = useRef<any>(null);
  const sources = useMemo(() => {
    if (typeof _sources === 'string') {
      return { uri: _sources, thumbnail: undefined };
    } else {
      return {
        uri: _sources?.uri,
        thumbnail: _sources?.thumbnail || undefined,
      };
    }
  }, [_sources]);


  return (
    <View
      style={{
        flex: 1,
        position: 'relative',
        width,
        height,
        ...containerStyle,
      }}
    >
      {Platform.OS === 'web' ? (
        <>
          {type === 'youtube' ?
            <ReactPlayer
              url={`${sources?.uri}`}
              light={sources.thumbnail}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
              }}
              width="100%"
              height="100%"
              ref={videoRef}
              onReady={() => {
                // videoRef.current.seekTo(2,'');
              }}
              onStart={() => {
                videoRef.current.seekTo(2, '');
              }}
              controls
              onProgress={(status) =>
                getWatchDuration && getWatchDuration(status?.playedSeconds)
              }
            /> : <WebVideo
              options={{
                autoplay: false,
                controls: true,
                responsive: true,
                fluid: true,
                sources: [{
                  src: `${sources.uri}`,
                }],
                fullscreen: {
                  options: {
                    width: '100%',
                    height: '100%',
                  }
                },
                // width,
                // height,
              }}

              // url={`${sources.uri}`}
              // light={sources.thumbnail}
              // style={{
              //   position: 'absolute',
              //   top: 0,
              //   left: 0,
              // }}
              width={width}
              height={height}
              onProgress={(status: any) => {
                getWatchDuration && getWatchDuration(status?.playedSeconds);
              }}
              onError={onError}
            // ref={videoRef}
            // // onReady={() => {
            // //   videoRef.current.seekTo(2,'');
            // // }}
            // // onStart={() => {
            // //   videoRef.current.seekTo(2,'');
            // // }}
            // controls
            // onProgress={(status) =>
            //   getWatchDuration && getWatchDuration(status?.playedSeconds)
            // }
            // config={{
            //   file: {
            //     attributes: {
            //       controlsList: 'nodownload', // Prevents download in some browsers
            //     },
            //   },
            // }}
            // {...reactPlayerProps}
            />
          }

        </>
      ) : type === 'youtube' ? (
        <View>
          <WebView
            source={{
              uri: sources?.uri.split('.').includes('jpg')
                ? sources?.uri.replace('.jpg', '')
                : sources?.uri,
            }}
            style={{
              flex: 1,
              height,
              width: width - 370,
            }}
          />
        </View>
      ) : (
        <View>
          <Video
            style={cleanObject({
              height,
              width: width,
            })}
            ref={videoRef}
            source={{ uri: sources?.uri }}
            useNativeControls
            resizeMode={ResizeMode.CONTAIN}
            isLooping
            onFullscreenUpdate={onFullscreenUpdate}
            onPlaybackStatusUpdate={(status: any) =>
              getWatchDuration &&
              getWatchDuration(status?.positionMillis / 1000)
            }
          />
        </View>
      )}
    </View>
  );
}

export default memo(VideoPlayer);
