import React from 'react';
import { TouchableOpacity } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { View, Text, useSx, } from 'dripsy';
interface Props {
    label: string,
    hideDelete?: boolean
    onDelete?: () => void
}
const Chip = ({ label, hideDelete=false, onDelete }: Props) => {
    const sx = useSx();
    return (
        <View style={sx({
            flexDirection: 'row',
            display:'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin:1,
            borderRadius: 25,
            backgroundColor: '$white',
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: 0,
            },
            shadowOpacity: 0.55,
            shadowRadius: 2.22,
            elevation: 3,
        })}>
            <Text style={sx({ marginRight: 2, padding: 2 })}>{label}</Text>
            {!hideDelete ? <TouchableOpacity
                style={sx({
                    padding: 2,
                    shadowColor: '#000',
                    shadowOffset: {
                        width: 0,
                        height: 0,
                    },
                    shadowOpacity: 0.55,
                    shadowRadius: 2.22,
                    elevation: 3,
                    borderRadius: 25,
                })}

                onPress={onDelete}>
                <MaterialIcons name="close" size={15} color="red" />
            </TouchableOpacity> : null}
        </View>
    );
};
export default Chip;
