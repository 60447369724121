import { useDim } from 'app/redux/hooks/useDim';
import { FlatList, useSx, View } from 'dripsy';
import React, { useEffect, useRef, useState } from 'react';
import { Carousel } from 'app/components/carousel';
import SubscriptionPackages, {
  IconButtonLeft,
  IconButtonRight,
  ScrollLeftIcon,
  ScrollRightIcon,
} from '../client-subscriptions/subscription-lists';
import ExamCard from '../exams/exams/exam-card';
import { MaterialCard } from 'app/components/card/material-card';
import { Gradient } from 'dripsy/gradient';
import { ClassRoomCard } from 'app/components/card/classroom-card';
import { useSelector } from 'react-redux';
import ExamCategoriesCard from './exam-category-card';
import { getClientConfig } from '../../redux/memoizedSelectors';

function ContentList({ details }: any) {
  const { width } = useDim();
  const sx = useSx();
  const refCarousel = useRef<any>(null);
  const [contentOffset, setContentOffset] = useState<number>(0);
  const [reachedEnd, setReachedEnd] = useState<boolean>(false);
  const { clientConfig } = useSelector(getClientConfig);

  const _getContentCard = (type, data) => {
    if (type === 'materials') {
      return (
        <MaterialCard
          style={{ maxWidth: 400 }}
          src={data?.cover_s3}
          {...data}
          material={data}
          author={`${
            data?.author?.firstName
              ? `${data?.author?.firstName} ${data?.author?.lastName}`
              : `${clientConfig?.name} user`
          }`}
        />
      );
    } else if (type === 'exams') {
      return (
        <ExamCard
          user={undefined}
          permission={[]}
          client={undefined}
          data={data}
          type="practice"
        />
      );
    } else if (type === 'classrooms') {
      return (
        <ClassRoomCard
          {...data}
          style={{ minHeight: 170 }}
          avatar={data?.avatar}
          author={
            data?.moderator
              ? `${data?.moderator.firstName} ${
                  data?.moderator.lastName ? data?.moderator.lastName : ''
                }`
              : ''
          }
        />
      );
    } else if (type === 'examsCategories') {
      return (
        <ExamCategoriesCard
          _id={data?._id}
          name={`${data?.name}`}
          href={`/${data?.pageUrl}`}
          imgUrl={data?.imgUrl}
          key={`s-${data?._id}`}
          nextExamDate={data?.nextExamDate}
          totalTestCount={data?.totalTestCount}
        />
      );
    } else {
      return <></>;
    }
  };

  const scrollLeft = () => {
    refCarousel.current?.scrollToOffset({
      offset: contentOffset - 320,
      animated: true,
    });
    setReachedEnd(false);
  };

  const scrollRight = () => {
    refCarousel.current?.scrollToOffset({
      offset: contentOffset + 320,
      animated: true,
    });
  };

  useEffect(() => {
    if (details?.contentList?.length || contentOffset === 0) {
      setReachedEnd(details?.contentList?.length * 300 < 930);
    }
  }, [width, details?.contentList?.length]);

  return (
    <View
      style={sx({
        margin: 'auto',
        marginBottom: 4,
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
      })}
    >
      {details?.contentType === 'subscriptions' ? (
        <SubscriptionPackages
          preference={undefined}
          user={undefined}
          continueUrl={''}
          containerWidth={'100%'}
          homepage={true}
          subscriptions={details?.contentList}
        />
      ) : (
        <></>
      )}

      {details?.contentType !== 'subscriptions' &&
        (width > 600 ? (
          <View style={{ display: 'flex', justifyContent: 'center' }}>
            {contentOffset > 0 ? (
              <IconButtonLeft
                disabled={contentOffset === 0}
                onPress={() => scrollLeft()}
                style={[
                  sx({
                    width: [0, 30],
                    display: ['none', 'flex'],
                    height: [55, 55, 65, 50],
                  }),
                ]}
              >
                <Gradient
                  start={{ x: 0, y: 1 }}
                  end={{ x: 1, y: 1 }}
                  colors={['#657c97', '#99999910']}
                  style={sx({
                    flex: 1,
                    padding: [0, 1],
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 35,
                    borderRadius: 5,
                  })}
                >
                  <ScrollLeftIcon
                    name="leftIcon"
                    fill={['white']}
                    style={[sx({ width: [0, 30], display: ['none', 'flex'] })]}
                  />
                </Gradient>
              </IconButtonLeft>
            ) : (
              <></>
            )}
            <FlatList
              ref={refCarousel}
              horizontal
              scrollEnabled
              showsHorizontalScrollIndicator={false}
              data={details?.contentList}
              onScroll={(e) => {
                setContentOffset(() => {
                  if (e?.nativeEvent?.contentOffset?.x < contentOffset) {
                    setReachedEnd(false);
                  }
                  return e?.nativeEvent?.contentOffset?.x;
                });
              }}
              contentContainerStyle={sx({
                justifyContent: width > 768 ? 'center' : null,
              })}
              renderItem={({ item, index }: { item: any; index: number }) => {
                return (
                  <View key={index}>
                    {_getContentCard(details?.contentType, item)}
                  </View>
                );
              }}
              onEndReachedThreshold={0.1}
              onEndReached={({ distanceFromEnd }) => {
                distanceFromEnd >= 0 && setReachedEnd(true);
              }}
            />

            {reachedEnd ? (
              <></>
            ) : (
              <IconButtonRight
                onPress={() => scrollRight()}
                style={[
                  sx({
                    width: [0, 30],
                    display: ['none', 'flex'],
                    height: [55, 55, 65, 50],
                  }),
                ]}
              >
                <Gradient
                  start={{ x: 0, y: 1 }}
                  end={{ x: 1, y: 1 }}
                  colors={['#99999910', '#657c97']}
                  style={sx({
                    flex: 1,
                    padding: [0, 1],
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 35,
                    borderRadius: 5,
                  })}
                >
                  <ScrollRightIcon
                    name="leftIcon"
                    fill={['white']}
                    style={[sx({ width: [0, 30], display: ['none', 'flex'] })]}
                  />
                </Gradient>
              </IconButtonRight>
            )}
          </View>
        ) : (
          <Carousel
            {...{
              vertical: false,
              width: width > 767 ? width - (false ? 127 : 352) : width - 10,
              height: 240,
            }}
            loop
            autoPlay={true}
            pagingEnabled={true}
            snapEnabled={true}
            autoPlayInterval={3000}
            windowSize={10}
            sliderDot
            sliderDotContainerStyle={{}}
            sliderDotStyle={{
              width: 8,
              height: 8,
            }}
            data={details.contentList}
            renderItem={({ item, index }) => (
              <View key={index}>
                {_getContentCard(details?.contentType, item)}
              </View>
            )}
          />
        ))}
    </View>
  );
}

export default ContentList;
