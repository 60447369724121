import React from 'react';
import { View, Text, useSx, SxProp } from 'dripsy';
import { TextStyle } from 'react-native';

export const Heading = ({
  headingName,
  subHeading,
  width,
  marginleft,

  containerSx = {},
  textStyle = {}
}: {
  headingName: string;
  subHeading?: string;

  width: any;
  marginleft: any;
  containerSx?: SxProp;
  textStyle?: TextStyle
}) => {
  const sx = useSx();
  return (
    <View
      style={sx({
        flexDirection: 'row',
        marginTop: 10,
        marginBottom: 10,
        ...containerSx,
      })}
    >
      <View
        style={[
          sx({
            borderRadius: 10,
            width: width,
            backgroundColor: '#F47979',
          }),
        ]}
      ></View>
      <View
        style={[sx({ flex: 1, flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' })]}
      >
        <Text
          style={[sx({
            marginLeft: marginleft,
            fontSize: [24, 24, 26, 34],
            lineHeight: [27, 27, 27, 51],
            fontWeight: '700',
            color: '#2F3443',
          }), textStyle]}
        >
          {headingName}
          {subHeading && (
            <Text
              style={[sx({
                display: ['block', null, 'inline-flex'],
                marginLeft: [0, null, 11],
                fontSize: [17, 17, 17],
                lineHeight: [26],
                color: '#636363',
                fontWeight: '600',
              }), textStyle]}
            >
              {subHeading}
            </Text>
          )}
        </Text>
      </View>
    </View>
  );
};
